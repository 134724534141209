<template>
  <div class="api-section">
    <div class="api-key-detail">
      <h3>Your Tiny API Key</h3>
      <p class="light-text">
        Your API Key is a unique token that links your account <br />with our
        platform. Please keep it <strong>safe.</strong>
      </p>
      <div class="api-key-value copy-btns">
        <h1 id="inputText" v-if="showApiKey">{{ apiKey }}</h1>
        <h1 v-if="!showApiKey">{{ "********" }}</h1>
        <button
          type="button"
          v-on:click="copyApi"
          class="btn primary-btn copy-btn"
        >
          <span v-if="!show">COPY</span
          ><span v-if="show" style="font-size: 17px">&#10004;</span>
        </button>
        <button
          type="button"
          v-on:click="showApiKey = !showApiKey"
          class="btn primary-btn hide-btn"
        >
          <span v-if="showApiKey">Hide</span>
          <span v-if="!showApiKey">Show</span>
        </button>
      </div>
      <p class="font-lg-14">
        You have <span class="primary-text">{{ credit }}</span> credits
        remaining in your key.
      </p>
    </div>
    <div class="documentation-detail pt-lg-40">
      <h3>Documentation</h3>
      <p>
        We have collected all possible information about API which you can get
        acquainted. If you will have any questions about how to use API please
        <a href="https://datagmaapi.readme.io/" target="_blank"
          >View API Documentation.</a
        >
      </p>
    </div>
    <div class="need-help-detail">
      <h3>Need Help?</h3>
      <p>
        Please <a href="mailto:raphael@datagma.com">contact us</a> if you have
        any questions related to the API’s
      </p>
    </div>
    <!-- <leftTopbar @updateUserData="update" /> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  Setuser_credit,
  creditService,
} from "../../common/services/getUserCredit.service";
import { GET_USER_API_ID } from "../../../store/constant/actions.type";
import { loadingService } from "../../common/services/loading.service";
export default {
  name: "api",
  data() {
    return {
      credit: "",
      apiKey: "",
      show: false,
      showApiKey: false,
      fullPage: true,
      loader: "bars",
      color: "#003172",
      height: 50,
      width: 55,
    };
  },
  methods: {
    copyApi() {
      this.$clipboard(this.apiKey);
      this.show = !this.show;
    },
    getUserApiId() {
      loadingService.setloader(true);
      this.$store
        .dispatch(GET_USER_API_ID)
        .then((data) => {
          if (data) {
            this.apiKey = data.apiId;
            localStorage.setItem("$s", data.apiId);
            Setuser_credit();
            creditService.getCredit().subscribe((Credit) => {
              if (Credit) {
                this.credit = Credit;
              } else {
                this.credit = "";
              }
            });
            loadingService.setloader(false);
          }
        })
        .catch((err) => {
          loadingService.setloader(false);
          this.flashMessage.error({
            title: "Error Message",
            message: err.message,
          });
        });
    },
  },
  async beforeMount() {
    this.$eventBus.$emit("callCreditMethod");
    this.getUserApiId();
  },
  mounted() {
    // let externalScript = document.createElement("script");
    // externalScript.setAttribute("src", "https://cdn.firstpromoter.com/fpr.js");
    // // externalScript.setAttribute("cid", "9iau5tg8");
    // document.head.appendChild(externalScript);
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
<style scoped>
.api-section {
  box-sizing: border-box;
  border-radius: 3px;
  max-width: 850px;
}
.api-key-detail {
  background: #f6faff;
  border: 1px solid #e9f3ff;
  padding: 24px 155px 48px 80px;
}
.api-key-value {
  background: #e9f3ff;
  border-radius: 3px;
  padding: 24px;
  margin-bottom: 15px;
  display: inline-block;
}
.api-key-value h1 {
  font-size: 26px;
  line-height: 48px;
  margin-bottom: 0;
  padding: 24px 97px 24px 24px;
  word-break: break-all;
  font-weight: 400;
}
.api-key-value button {
  float: right;
}
.copy-btns .hide-btn {
  float: right;
  height: 40px;
  width: 60px;
}
.api-key-value.copy-btns {
  width: 100%;
  max-width: 300px;
}
.copy-btns .copy-btn {
  float: right;
  height: 40px;
  width: 60px;
  margin-left: 10px;
}
@media only screen and (max-width: 769px) {
  .api-key-detail {
    padding: 15px 15px 15px 15px;
  }
  .api-key-value h1 {
    padding: 0;
  }
}
</style>
